import React from "react";
import "./Szolgaltatasok.css";
import Illusztracio from "../assets/tuskomaras_illusztracio.jpeg";
import Tuskomaras1 from "../assets/tuskomaras_1.png";
import Tuskomaras2 from "../assets/tuskomaras_2.png";
import Text from "../assets/text_icon.png";
import Viber from "../assets/viber_icon.png";
import Mobile from "../assets/mobile_icon.png";

function Szolgaltatasok() {
  return (
    <div className="szolgaltatasok">
      <h1 className="elso-h1" id="veszelyes-fak-visszavagasa-kivagasa">VESZÉLYES FÁK VISSZAVÁGÁSA / KIVÁGÁSA</h1>
      <h2>Kidőlt? Elszáradt? Viharkáros?</h2>
      <h2>
        <u>Rövid határidőn belül megoldjuk!</u>
      </h2>
      <h3>
        Veszélyessé vált fák, szakszerű gallyazását és/vagy kivágását is
        vállaljuk egész Pest megyében.
        <br />
        Adott esetben emelőkosaras daru segítségével végezzük el a kívánt
        munkát!
      </h3>

      <h1 id="fak-lebontasa-biztonsagosan">FÁK LEBONTÁSA BIZTONSÁGOSAN</h1>
      <h3>
        Szűk helyen, épületekkel körbevett fákat, alpin technikával,
        <br />
        gallyanként vágjuk, szükség esetén kötéllel eresztjük le, majd a törzset
        darabokban bontjuk le egészen a földszintig.
      </h3>

      <h1 id="telektisztitas-bozotirtas">TELEK TISZTÍTÁS, BOZÓTÍRTÁS</h1>
      <h3>
        Vállaljuk kisebb telkek, vagy akár nagyobb építési telkek előkészítését
        is, <br/>teljeskörű (földfelszín feletti), elhanyagolt növényzettől való
        megtisztítását,<br/> fűkaszálással, bozótirtással, a keletkező zöldhulladék
        elszállításával.
      </h3>
      <h4>Telektisztitásra árajánlatot kizárólag a munkaterület <br/>
      személyes megtekintése után tudunk adni.</h4>

      <h5>További információért, valamint időpontért hívjon!</h5>
      <div className="flex">
        <img className="mobil" src={Mobile} alt="Telefonhívás" />
        <h3 className="contact">Demeter Zoltán:</h3>
        <a href="tel:+36306528049" style={{fontFamily: "Times New Roman"}}>
        <h3 className="number">+36 30 652 8049</h3>
        </a>
        <img className="text-icon" src={Text} alt="SMS küldése" />
        <a href="viber://chat?number=+36306528049/" target="_blank">
          <img className="viber-icon" src={Viber} alt="Viber üzenet küldése" />
        </a>
      </div>

      <h1 id="zold-hulladek-nyesedek-elszallitasa">ZÖLD-HULLADÉK (NYESEDÉK) ELSZÁLLÍTÁSA</h1>
      <h4>
        A munkánk során keletkezett fa, lomb, nyesedék zöld-hulladékot
        elszállítjuk.
      </h4>
      <h4>
        Az elszállított kertészeti hulladékot komposztüzembe szállítjuk, ahol
        újra
        <br />
        hasznosításra kerül, ezért a szállítás környezetbarát és gazdaságos.
      </h4>
      <h4>
        A zöld-hulladék elszállítás ára Budapest területén: 5500 FT + ÁFA/m3
      </h4>

      <h1 id="tuskomaras">Tuskómarás</h1>
      <h3>
        Tuskómarásra akkor is szükség lehet, ha a gyökerek között közművek
        kábelek mennek,
        <br />
        mert ilyenkor a géppel való kiásás nem jöhet szóba, mivel a közművek
        sérülése
        <br />
        nélkül szinte lehetetlen eltávolítani a tuskót.
      </h3>

      <div className="pricing-table">
        <table>
          <thead>
            <tr>
              <th colSpan="2">Tuskómarás árak 2024</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Árajánlat:</td>
              <td>Díjmentes</td>
            </tr>
            <tr>
              <td>Munkadíj és kiszállás minimum:</td>
              <td>25.000 Ft</td>
            </tr>
            <tr>
              <td>Talajszinten mért legnagyobb törzsátmérő:</td>
              <td>350 Ft/cm</td>
            </tr>
            <tr>
              <td>Felszínen futó gyökerek hossza folyóméterenként:</td>
              <td>5000 Ft/m</td>
            </tr>
            <tr>
              <td>Budapesten és Pest vármegyében a kiszállás:</td>
              <td>15.000 Ft</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>
        A tuskó átmérője nem feltétlenül csak a törzsnyak vastagságát jelenti,
        <br />
        hanem körülötte a talajszinten a földből még kilátszó gyökerekkel együtt
        számítandó,
        <br />
        mert azokon is végig kell menni a géppel! Ha csak a törzs látszik ki a
        földből
        <br />
        akkor azzal az átmérővel lehet nyugodtan számolni.
      </h3>

      <div className="flex">
        <img
          alt="Tuskómarás segédlet"
          className="illusztracio"
          src={Illusztracio}
        />
      </div>
      <div className="flex2">
        <img alt="Tuskómarás kép 1" className="tusko1" src={Tuskomaras1} />
        <img alt="Tuskómarás kép 2" className="tusko2" src={Tuskomaras2} />
      </div>
    </div>
  );
}

export default Szolgaltatasok;